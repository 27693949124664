import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuSideBar from "./component";

const mapStateToProps = ({
    user: {
        name,
        isLoggedIn,
        isFetched
    },
    myWishlist: {
        wishlistedCarsList
    }
}) => ({
    userName: name,
    isLoggedIn,
    isFetched,
    wishlistedCarsList
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuSideBar);
